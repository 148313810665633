import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { CognitoUser } from '@aws-amplify/auth'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'
import EventCountdownModal from '../../components/EventCountdownModal'
import { EventEntityResponseCollection } from '../../generated/graphql'
import EVENTS_QUERY from '../../queries/events'
import Destination from '../destination/Destination'
import Destinations from '../../components/destinations/destinations'
import PayGate from '../../components/paygate/paygate'
import CountryGate from '../../components/countryGate/countryGate'
// import LiveEvent from '../../components/liveEvent/liveEvent'

export const EventContext = React.createContext<EventEntityResponseCollection | undefined>(
  undefined
)

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: visible;
  justify-content: space-between;
  & > div {
    &:nth-child(1) {
      width: 100%;
      position: absolute;
      width: 100%;
      height: 100%;
      @media ${(props) => props.theme.breakpoints.md} {
        position: relative;
      }
    }
  }
`

const Home = ({ user }: { user: CognitoUser | undefined }) => {
  const { id, playlist } = useParams()
  const [userCompanyId, setUserCompanyId] = useState<number | undefined>()
  const [showEventInfoModal, setShowEventInfoModal] = useState(false)

  const { loading, data } = useQuery<{ events: EventEntityResponseCollection }>(EVENTS_QUERY, {
    // skip: !userCompanyId,
    variables: {
      time: dayjs().startOf('minute').toISOString(),
    },
    pollInterval: 1000 * 60,
  })
  const filteredEvents = useMemo(
    () =>
      data
        ? {
            ...data?.events,
            data:
              data?.events.data.filter(
                (d) =>
                  d.attributes?.public ||
                  !!d.attributes?.companies?.data.filter((c) => c.id === userCompanyId)
              ) || [],
          }
        : undefined,
    [data?.events.data.length, userCompanyId]
  )

  useEffect(() => {
    const localStorageKey = 'ticketInfoModalShownAt'
    const dayDiff = 1
    user?.getUserAttributes((e, d) => {
      const ticketCode = d?.find((v) => v.Name === 'custom:ticketCode')
      // check if local variable set and show modal if more than 1 day ago
      const ticketInfoModalShownAt = localStorage.getItem(localStorageKey)
      if (
        (ticketCode && !ticketInfoModalShownAt) ||
        dayjs().diff(dayjs(ticketInfoModalShownAt), 'day') > dayDiff
      ) {
        setShowEventInfoModal(true)
        localStorage.setItem(localStorageKey, dayjs().toISOString())
      }
    })
  }, [])

  useEffect(() => {
    user?.getUserAttributes((e, d) => {
      const company = d?.find((v) => v.Name === 'custom:companyId')
      if (!company) return setUserCompanyId(-1)
      return setUserCompanyId(Number(company.Value))
    })
  }, [])
  return (
    <EventContext.Provider value={filteredEvents}>
      <PayGate user={user} />
      {/* <CountryGate user={user} /> */}
      <Container>
        <Destination
          key={id}
          destinationId={id ? Number(id) : undefined}
          playlistId={playlist ? Number(playlist) : undefined}
        />
        <Destinations key="DESTINATIONS" user={user} />
      </Container>
      {showEventInfoModal && <EventCountdownModal onClose={() => setShowEventInfoModal(false)} />}
    </EventContext.Provider>
  )
}

export default Home
