import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import theme from '../../styles/theme'
import Logo from '../../assets/logo.png'
import Astronaut from '../../assets/Astronaut_freigestellt.png'

const Wrapper = styled.div<{ isEventScreen: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 1.5rem;
  align-items: center;
  background: ${theme.colors.antless1};
  align-items: ${({ isEventScreen }) => (isEventScreen ? 'flex-end' : 'center')};
  img {
    width: ${({ isEventScreen }) => (isEventScreen ? '90%' : '75px')};
    height: auto;
    display: ${({ isEventScreen }) => (isEventScreen ? 'block' : 'inline')};
    margin: ${({ isEventScreen }) => (isEventScreen ? '0 auto' : '0')};
  }
`

const AuthHeader = () => {
  const location = useLocation()
  const isEventScreen = location.pathname.includes('/register/event')
  return (
    <Wrapper isEventScreen={isEventScreen}>
      <div>
        {!isEventScreen && <img alt="Antless Logo" src={Logo} />}
        {isEventScreen && <img alt="Office in Motion - Come and fly with me" src={Astronaut} />}
      </div>
    </Wrapper>
  )
}

export default AuthHeader
